<template>
  <!-- the :value and @input allow v-model access to the parent of this component -->
  <div class="form-input-group">
    <div class="input-container">
      <input
        ref="mainInput"
        v-if="inputType === 'input'"
        :required="data.required"
        :type="this.isVisible ? 'text' : data.type"
        class="input-field"
        :class="dataName === 'password' ? 'has-addon-right' : ''"
        :id="dataName"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        placeholder=" " />
      <textarea
        v-if="inputType === 'textarea'"
        :required="data.required"
        :type="this.isVisible ? 'text' : data.type"
        class="textarea-field"
        :class="dataName === 'password' ? 'has-addon-right' : ''"
        :id="dataName"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"></textarea>
      <label
        :for="dataName"
        class="dissapearing-label"
        :class="{ required: data.required }"
        >{{ data.label }}</label
      >
      <span
        class="input-addon-right validation-icon"
        v-if="showValidation"
        @click="this.isVisible = !this.isVisible">
        <CheckIcon class="check-icon" v-if="validated && !loading"></CheckIcon>
        <CrossIcon class="cross-icon" v-if="invalid && !loading"></CrossIcon>
        <loading-circle
          :radius="10"
          :borderWidth="2"
          v-if="loading"></loading-circle>
      </span>
      <span
        class="input-addon-right"
        v-if="data.type == 'password' && !validated"
        @click="this.isVisible = !this.isVisible">
        <EyeIcon v-if="!isVisible" />
        <EyeOffIcon v-else />
      </span>
    </div>
    <Transition name="popout">
      <div
        class="error-messages"
        v-if="shownErrors.length"
        :class="shaking ? 'shaking' : ''"
        @animationend="shaking = false">
        <div class="error-text" v-for="error in shownErrors" :key="error">
          {{ error }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import EyeIcon from "vue-material-design-icons/Eye.vue";
import EyeOffIcon from "vue-material-design-icons/EyeOff.vue";
import CheckIcon from "vue-material-design-icons/Check";
import CrossIcon from "vue-material-design-icons/Close.vue";
import LoadingCircle from "./basic/LoadingCircle.vue";
export default {
  name: "InputField",
  components: {
    EyeIcon,
    EyeOffIcon,
    CheckIcon,
    CrossIcon,
    LoadingCircle,
  },
  props: {
    modelValue: {
      //used with the onInput method above to allow access to a v-model on this template
      type: String,
    },
    dataName: {
      type: String,
      default: "Input",
    },
    data: {
      type: Object,
      default: () => {
        return {
          label: "Label For The Input",
          required: false,
          help_text: "Some hint that gives more advice",
          type: "text",
          error: "", //not used for simplicity
        };
      },
    },
    inputType: {
      type: String,
      default: "input",
    },
  },
  methods: {
    shake() {
      this.shaking = true;
    },
  },
  data() {
    return {
      isVisible: false,
      shownErrors: [],
      shaking: false,
      validated: false,
      invalid: false,
      loading: false,
      showValidation: false,
    };
  },
  watch: {
    modelValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.shownErrors.splice(0);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input-group {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.input-container {
  width: 100%;
  display: flex;
  position: relative;
}

.input-container > .dissapearing-label {
  z-index: 2;
  color: $grey-400;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.9rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.input-field {
  padding-left: 0.4rem;
  height: 2.5rem;
  border-radius: $border-radius;
  border: none;
  background-color: $white;
  z-index: 1;
  :focus {
    outline: 2px solid $dark;
  }
  ::placeholder {
    color: $grey-400;
  }
}
.textarea-field {
  padding-left: 0.4rem;
  padding-top: 1rem;
  height: 10rem;
  width: 50ch;
  resize: none;
  border-radius: $border-radius;
  border: none;
  :focus {
    outline: 2px solid $dark;
  }
}
.input-field:focus + .dissapearing-label,
.input-field:not(:placeholder-shown) + .dissapearing-label {
  z-index: 2;
  opacity: 0.3;
  transform: scale(0.7) translateY(-0.5rem) translateX(-0.2rem);
}
.textarea-field:focus + .dissapearing-label {
  opacity: 0.3;
  transform: scale(0.7) translateY(-0.5rem) translateX(-0.2rem);
}
.input-container > input {
  width: 100%;
  margin-top: 5px;
  line-height: 2.2rem;
}
.input-field.has-addon-right {
  border-radius: 0.2rem 0 0 0.2rem;
}

.input-container > .input-addon-right {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border-left: solid transparent 2px;
  margin-left: -2px;
  border-radius: 0 0.2rem 0.2rem 0;
  &:hover:not(.validation-icon) {
    background-color: $light;
    cursor: pointer;
  }
}
.error-messages {
  max-width: 100%;
  border: solid $danger-500 1px;
  border-top: none;
  background-color: $white;
  padding: $item-margin;
  border-radius: 0 0 $border-radius $border-radius;
  overflow: hidden;
}
.error-text {
  color: $danger-500;
  font-weight: 300;
  overflow-wrap: break-word;
  padding-left: 0.5rem;
}
.error-text::before {
  content: "• ";
}

.validation-icon {
  background-color: $white;
  cursor: auto;
  &:hover {
    background-color: $white;
  }
}
.check-icon {
  color: $green;
}
.cross-icon {
  color: $red;
}
.shaking {
  animation: shaking 0.5s;
}
@keyframes shaking {
  0% {
    transform: translateX(-2px);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
